import React from "react";
import styled from "styled-components";
import { Container, Wrapper } from "../global";
import AkomapsLogo from "@studytools/core/ui/components/logo/AkoMapsLogo";

const Footer: React.FC = () => (
  <FooterWrapper id="footer">
    <FooterContainer>
      <FooterColumnContainer>
        <FooterColumn>
          <span>
            <AkomapsLogo />
          </span>
        </FooterColumn>
        <FooterColumn>
          <span>
            <a href="https://help.akomaps.com">Help</a>
          </span>
          <ul>
            <li>
              <a href="https://help.akomaps.com">Help and FAQ</a>
            </li>
            <li>
              <a href="/get-started">Get Started</a>
            </li>
            <li>
              <a href="/app/course/help/summary" target="_blank">
                Video Tutorials
              </a>
            </li>
            <li>
              <a href="/pricing">Pricing</a>
            </li>
          </ul>
        </FooterColumn>
        <FooterColumn>
          <span>
            <a href="/about">About Us</a>
          </span>
          <ul>
            <li>
              <a href="/the-team">The Team</a>
            </li>
            <li>
              <a href="/research">Research</a>
            </li>
          </ul>
        </FooterColumn>
        <FooterColumn>
          <span>Follow Us</span>
          <ul>
            <li>
              <a
                href="https://www.youtube.com/channel/UCC1yp3BROMtKgqWEWxmpjAQ"
                rel="noopener noreferrer"
                target="_blank"
              >
                YouTube
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/akomapsnz/" rel="noopener noreferrer" target="_blank">
                Facebook
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/akomapsofficial/" rel="noopener noreferrer" target="_blank">
                Instagram
              </a>
            </li>
            <li>
              <a href="https://twitter.com/AkoMaps" rel="noopener noreferrer" target="_blank">
                Twitter
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/cognitiveperformancelabs"
                rel="noopener noreferrer"
                target="_blank"
              >
                LinkedIn
              </a>
            </li>
          </ul>
        </FooterColumn>
      </FooterColumnContainer>
      <Copyright>
        <span>Copyright &copy; 2018 - {new Date().getFullYear()} Cognitive&nbsp;Performance&nbsp;Labs</span>
        <a href="/legal/terms-akomaps">Terms and conditions</a>
        <a href="/legal/privacy-policy">Privacy policy</a>
      </Copyright>
    </FooterContainer>
  </FooterWrapper>
);
const FooterWrapper = styled(Wrapper)`
  background-color: #000000e6;
  -webkit-text-fill-color: ${props => props.theme.color.link.nav};
  & a {
    color: ${props => props.theme.color.link.nav};
  }
`;

const FooterContainer = styled(Container)`
  padding-bottom: 32px;
  padding-left: 0;
  padding-right: 0;
  overflow-x: hidden;
`;

const FooterColumnContainer = styled(Container)`
  display: grid;
  padding: 0 1em;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    grid-gap: 8px;
  }
`;
const FooterColumn = styled.div`
  padding: 0;
  ul {
    display: grid;
    padding: 0;
    li {
      font-family: ${props => props.theme.font.normal};
      ${props => props.theme.fontSize.xsmall};
      @media (max-width: ${props => props.theme.screen.xs}) {
        ${props => props.theme.fontSize.xxsmall};
      }
    }
  }
  > img {
    width: 200px;
    height: 200px;
    @media (max-width: ${props => props.theme.screen.xs}) {
      width: 150px;
      height: 150px;
    }
  }
`;
const Copyright = styled.div`
  ${props => props.theme.fontSize.xxxsmall};
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 2em 1em 0;
  display: flex;
  flex-flow: row wrap;
  > a,
  > span {
    margin-right: 20px;
  }
`;

export default Footer;
