export const signUpLink = "/app/sign-up";
export const loginLink = "/app/";

export const sendSignupConversionEvent = (): void => {
  if (window) {
    const { gtag } = window as any;
    if (gtag) {
      gtag("event", "conversion", { send_to: "AW-585559785/bkO5CKTuk-ABEOndm5cC" });
    }
  }
};

export const signUp = (): void => {
  window.location.href = signUpLink;
  sendSignupConversionEvent();
};

export const login = (): void => {
  window.location.href = loginLink;
  sendSignupConversionEvent();
};
